import { Link, useOutlet } from "react-router-dom";

interface Props extends React.PropsWithChildren {
  defaultChild?: React.ReactElement;
}

export default function GlobalContainer(props: Props) {
  const year = new Date();

  const outlet = useOutlet();
  const defaultOutlet = outlet === null ? props.defaultChild : outlet;

  return (
    <>
      <div className={"max-w-4xl container px-2 mx-auto"}>
        <div className="items-center justify-between flex-col flex mt-8">
          <h1 className="text-5xl font-mono font-semibold">
            <Link to="/">楼中凤</Link>
          </h1>
          <hr />
        </div>
        <div
          className={"flex justify-between p-4 mt-8 rounded-md bg-slate-900"}
        ></div>
      </div>

      <div className={"max-w-4xl px-2 container mx-auto"}>
        <main className="flex flex-col justify-between">{defaultOutlet}</main>
      </div>

      <footer>
        <div
          className={
            "max-w-4xl px-4 container mx-auto mt-9 text-center text-xs"
          }
        >
          <span color="muted">
            Copyright © {year.getFullYear()} 致敬所有努力的女性
          </span>
          <div className={"mt-2"}></div>
        </div>
      </footer>
    </>
  );
}
