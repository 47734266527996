import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./App.css";
import Home from "./ui/home/home";
import GlobalContainer from "./ui/layout/GlobalContainer";
import { RecoilRoot } from "recoil";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <GlobalContainer />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/showme",
          element: <div />,
        },
        {
          path: "/register",
          element: <div />,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <RecoilRoot>
        <RouterProvider router={router} />
      </RecoilRoot>
    </div>
  );
}

export default App;
