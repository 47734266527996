interface Props extends React.PropsWithChildren {
  defaultChild?: React.ReactElement;
}

function Home(props: Props) {
  return (
    <>
      <h3>楼中凤 Bot</h3>
      <p>一个公益项目，永久免费。</p>
      <p>指在帮助海内外「从业」女性获得更好的生活。</p>
    </>
  );
}

export default Home;
